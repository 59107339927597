import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useSetPageTitle } from '@thriveglobal/thrive-web-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ROUTES } from './../enums/route'
import JourneysMicrosteps from './../pages/journeysMicrosteps/JourneysMicrosteps' // Not lazy as it is just a redirect

const messages = defineMessages({
    pageTitle: {
        defaultMessage: 'Thrive - Today',
        description: 'Thrive Today Page Title'
    }
})

const Router: React.FC = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const PAGE_TITLE = formatMessage(messages.pageTitle)

    useSetPageTitle(PAGE_TITLE)

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.MICROSTEPS}
                            component={JourneysMicrosteps}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
