import { Redirect, useParams } from 'react-router-dom'
import { ROUTES } from './../../enums/route'

function JourneysMicrosteps() {
    const { id } = useParams()
    if (id) {
        return <Redirect to={`${ROUTES.JOURNEYS_MICROSTEPS}?microstep=${id}`} />
    }
    return <Redirect to={ROUTES.JOURNEYS_MICROSTEPS} />
}

export default JourneysMicrosteps
